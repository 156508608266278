body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

.bounce {
  -webkit-animation: bounce 1s infinite alternate;
  -moz-animation: bounce 1s infinite alternate;
  animation: bounce 1s infinite alternate;
}

@-webkit-keyframes bounce {
  to { -webkit-transform: scale(1.2); }
}
@-moz-keyframes bounce {
  to { -moz-transform: scale(1.2); }
}
@keyframes bounce {
  to { transform: scale(1.2); }
}

.moving-gradient {
  width: 100%;
  height: 100%;
  color: #fff;
  /*background: blue;*/
  background: linear-gradient(-45deg, #1c2945 0%, #6589c0 66%, #1c2945 100%);
  background-size: 200% 200%;
  -webkit-animation: Gradient 10s ease-in infinite alternate;
  -moz-animation: Gradient 10s ease-in infinite alternate;
  animation: Gradient 10s ease-in infinite alternate;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 20px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 20px solid #fff !important;
}

/*@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
@keyframes Gradient {
  0% {
    background-position: 0% 0%
  }
  25% {
    background-position: 100% 0%
  }
  50% {
    background-position: 100% 100%
  }
  75% {
    background-position: 0% 100%
  }
  100% {
    background-position: 0% 0%
  }
}
*/

@keyframes Gradient {
  0% {
    background-position: 00% 0%
  }
  100% {
    background-position: 75% 100%
  }
/*  100% {
    background-position: 0% 0%
  }*/
}







/*@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}*/

/*

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}


@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}*/